import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import "./LeafletMap.css";
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const LeafletMap = () => {
  const position = [33.3436, 74.3436];
  const lahoreLocation = [31.45104598863964, 74.45165648148432];
  // const islamabadLocation = [33.627761975387216, 73.12029454028006];
  // const kpkLocation = [34.00195715996371, 71.54533165304267];

  return (
    <div className='reveal-from-top transition-from-top'>
      <MapContainer 
        center={position} 
        zoom={6} 
        style={{height: "40rem", width: "100%"}}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        
        <Marker position={lahoreLocation}>
          <Popup>
            <p style={{color: "black", fontSize: "1.2rem"}}><strong>Lahore, Punjab, Pakistan (Head Office)</strong> <br /> Main Boulevard, DHA Phase 9 Town, Workshop Stop, Bedian Road</p>
          </Popup>
        </Marker>
        {/* <Marker position={islamabadLocation}>
          <Popup>
            <p style={{color: "black", fontSize: "1.2rem"}}><strong>Islamabad, Federal Capital Territory, Pakistan</strong> <br /> House No: 209, Street 6B, Ghouri Town VIP Block Khanna Dak.</p>
          </Popup>
        </Marker>
        <Marker position={kpkLocation}>
          <Popup>
            <p style={{color: "black", fontSize: "1.2rem"}}><strong>Peshawar, KPK, Pakistan</strong> <br /> SF 869 DEANS Trade Center Peshawar</p>
          </Popup>
        </Marker> */}
      </MapContainer>
    </div>
  );
};

export default LeafletMap;
